import { useAppDispatch } from "store";

import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs from "hooks/breadcrumbs";
import { TShopItem } from "types";

import { CardBody, CardHeader, GradeProduct } from "components";
import { CartButton, Image } from "elements";
import { createBreadcrumbsChain } from "features";

import "./ProductCard.scss";

interface IProps {
  item: TShopItem;
  navLinks: { label: string; link: string }[];
}

const ProductCard = ({
  item: {
    Наименование,
    УРЛКартинки,
    Цена,
    ЦенаСоСкидкой,
    Рейтинг,
    Тег,
    Артикул,
    Вес,
    УРЛКартинкиПревью,
    ЕдИзм,
    Квант,
    Скидка,
    КоличествоОтзывов,
  },
  navLinks,
}: IProps) => {
  const [productInfo, setProductInfo] = useState(true);

  const { navLinks: productBreadcrumbsLinks } = useBreadcrumbs(`/product/${Артикул}`, Артикул, productInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!productInfo) dispatch(createBreadcrumbsChain(productBreadcrumbsLinks));
  }, [productInfo]);

  const sale = useMemo(() => {
    if (!(Цена ?? null) === null || (ЦенаСоСкидкой ?? null) === null) return null;
    return `-${(Скидка / (Цена / 100)).toFixed(0)}%`;
  }, [Цена, ЦенаСоСкидкой, Скидка]);

  const createBreadcrumb = () => {
    if (navLinks.length) {
      dispatch(createBreadcrumbsChain([...navLinks, { label: Наименование, link: `/product/${Артикул}` }]));
    } else {
      setProductInfo(false);
    }
  };

  return (
    <div className="container-cards">
      <Link to={`/product/${Артикул}`} className="product-card-info">
        <div className="product-card-image">
          <CardHeader tag={Тег?.includes("₽") ? sale : Тег} article={Артикул} />
          <Image onClick={createBreadcrumb} src={УРЛКартинкиПревью || УРЛКартинки} alt={УРЛКартинки} />
          {Рейтинг || КоличествоОтзывов ? <GradeProduct grade={Рейтинг} rewiews={КоличествоОтзывов} /> : null}
        </div>

        <CardBody
          onClick={createBreadcrumb}
          name={Наименование}
          price={Цена}
          discountedPrice={ЦенаСоСкидкой}
          article={Артикул}
          unit={ЕдИзм}
        />
      </Link>{" "}
      {/*TODO скрываем авторизацию и корзину*/}
      {/*<CartButton*/}
      {/*  price={Цена}*/}
      {/*  discountedPrice={ЦенаСоСкидкой}*/}
      {/*  article={Артикул}*/}
      {/*  weight={Вес}*/}
      {/*  unit={ЕдИзм}*/}
      {/*  step={Квант}*/}
      {/*  discount={Скидка}*/}
      {/*/>*/}
      {/*  </Link> */}
    </div>
  );
};

export default ProductCard;
