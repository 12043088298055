import { FC } from "react";

import { Like } from "elements";

import "./content.scss";

interface IProps {
  tag: string | null;
  article?: string;
}

const CardHeader: FC<IProps> = ({ tag, article }) => {
  return (
    <div className="container-head-card">
      <div className={tag ? "data-sale" : "__none-discount"}>
        <p className="sale-text">{tag}</p>
      </div>
        {/*TODO скрываем авторизацию и корзину*/}
      {/*<div>*/}
      {/*  <Like article={article ? article : null} />*/}
      {/*</div>*/}
    </div>
  );
};

export default CardHeader;
