import {useAppDispatch, useAppSelector} from "store";

import { Col } from "react-bootstrap";
import {TShopInformationBlockProducts, TShopInformationBlockVendorCode, TShopItem, TShopItemFull} from "types";

import { Button } from "elements";
import {getItems, isLoginSelector, setCart, toggleAuthModal, toggleMobileAppModal} from "features";
import {useCallback, useEffect, useMemo, useState} from "react";

interface IProps {
  ingredients: TShopInformationBlockProducts[];
  articles: TShopInformationBlockVendorCode[];
}

const Ingredients = ({ ingredients, articles }: IProps) => {
  const isLogin = useAppSelector(isLoginSelector);
  const dispatch = useAppDispatch();

  const deliveryMethod = localStorage.getItem("selectedOrderingMethod");
  const articlesList = useMemo(() => articles?.map(({ Артикул }) => Артикул), [articles]);
  const arrayArticles = Array.from(new Set(articlesList));
  const [receiptItems, setReceiptItems] = useState([]);
  useEffect(() => {
    dispatch(
      getItems({
        Артикулы: arrayArticles,
        НомерСтраницы: 1,
        КодСортировки: 1,
      })
    ).then((res) => {
      //@ts-ignore
      setReceiptItems(res.payload["Data"][0]["Data"]);
    });
  }, [articlesList]);

  const handleCardAdded = async () => {
    // todo: вызывать авторизацию если не авторизован, иначе - положить товары в корзину

    if (!isLogin)
      dispatch(toggleAuthModal(true));
    else {
      console.log('Authorized')
      const items = receiptItems.map((x:TShopItem) => ({
        ЕстьВКаталоге: true,
        АвтоЗаказ: false,
        Количество: 1,
        Цена: x.Цена,
        ЦенаСоСкидкой: x.ЦенаСоСкидкой,
        СкидкаПоПромокоду: 0,
        Скидка: x.Скидка,
        Артикул: x.Артикул,
        Вес: x.Вес
      }));
      console.log(items)
      await dispatch(
        setCart({
          ВключаяАвтоЗаказ: false,
          //@ts-ignore
          Корзина: receiptItems,
          ССайта: true,
          ТипДоставки:  deliveryMethod === "delivery"
            ? "ДоставкаИзМагазина"
            : "Самовывоз"
        })
      );
    }
  };
  return (
    <Col className="col-ingredients">
      <p className="title-ingredients">Ингредиенты</p>
      <div className="box-ingredients">
        {ingredients.map(({ Наименование, Порция }, index) => (
          <div className="box-ingredient" key={`ingredient-${index + 1}`}>
            <p className="ingredient">{Наименование}</p>
            <hr className="line" />
            <p className="ingredient-weight">{Порция}</p>
          </div>
        ))}
      </div>
      <pre>{JSON.stringify(ingredients)}</pre>
        {/*TODO скрываем авторизацию и корзину*/}
      {/*<div className="box-button-add-to-cart">*/}
      {/*  <Button*/}
      {/*    text={"Положить в корзину"}*/}
      {/*    onClick={handleCardAdded}*/}
      {/*    buttonClass={"button-add-to-cart"}*/}
      {/*    labelClass={"button-text-add-to-cart"}*/}
      {/*  />*/}
      {/*</div>*/}
    </Col>
  );
};

export default Ingredients;
