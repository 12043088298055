import { useAppSelector } from "store";

import { FC, useEffect, useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import burger from "image/burger.svg";
import useBreakpoint from "use-breakpoint";

import { NavMenu, SearchInput } from "components";
import { CatalogButton } from "elements";
import {
  isHeaderSearchInputOpenSelector,
  isLoginSelector,
  settingsResultSelector,
} from "features";

import { AccountButton } from "./sections/AccountButton";
import { CartButton } from "./sections/CartButton";
import { CurrentCity } from "./sections/CurrentCity";
import { DeliveryButton } from "./sections/DeliveryButton";
import { SmartLogo } from "./sections/SmartLogo";

import "./Header.scss";
import "bootstrap/dist/css/bootstrap.min.css";

type THeaderLink = {
  name: string;
  link: string;
};

const headerLinksTop: THeaderLink[] = [
  { name: "Магазины", link: "/stores" },
  // { name: "Доставка и оплата", link: "/shipping-and-payment" },
  { name: "Карьера", link: "/career" },
];
const headerLinks: THeaderLink[] = [
  { name: "Акции", link: "/promotions" },
  { name: "Клуб Smart", link: "/club-smart" },
  { name: "Партнерам", link: "/partners" },
  { name: "Контакты", link: "/contacts" },
  { name: "О компании", link: "/about" },
];

const BREAKPOINTS = {
  tablet: 431,
  mobileLarge: 0,
};

const HeaderBar: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { pathname } = useLocation();
  const isLogin = useAppSelector(isLoginSelector);
  const isSearchInputOpen = useAppSelector(isHeaderSearchInputOpenSelector);
  const {
    ДоступенКаталогДоставкиИзМагазина = false,
    ДоступенКаталогСамовывозаИзМагазина = false,
  } = useAppSelector(settingsResultSelector) ?? {};

  useEffect(() => {
    if (isOpen) setIsOpen(false);
  }, [pathname]);
  return (
    <>
      <header className={`header`}>
        {breakpoint !== "mobileLarge" && (
          <div className="header-top">
            <div className="box-header-top">
              <Container
                className="container-bootstrap __container-header-top"
                fluid="xl"
              >
                <Row className="justify-content-md-spacebetween __row-header-header">
                  <Col
                    xl={8}
                    md={9}
                    className="justify-items-center __col-nav-menu-header-top"
                  >
                    <NavMenu
                      containerClassName={"container-nav"}
                      arrayLists={headerLinksTop}
                      modifier={""}
                    />
                  </Col>
                  <Col xl={4} md={3} className="__col-current-city">
                    <CurrentCity />
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        )}

        <div className="header-body">
          <Container className="container-bootstrap" fluid="xl">
            <Row className="justify-content-md-spacebetween __class">
              <Col xl={4} lg={"auto"} md={3} xs={2}>
                <Row>
                  <Col xl={4} lg={4}>
                    <SmartLogo />
                  </Col>
                  <Col xl={8} lg={"auto"}>
                    <h1 className="title-h1">низкие цены для лучшей жизни</h1>
                  </Col>
                </Row>
              </Col>
              {breakpoint !== "mobileLarge" ? (
                <Col xl={8} lg={"auto"} md={"auto"} xs={"auto"}>
                  <NavMenu
                    containerClassName={"container-nav-body"}
                    arrayLists={headerLinks}
                    modifier={"_body-nav"}
                  />
                </Col>
              ) : (
                <div className="burger-image-container">
                  <img
                    onClick={() => setIsOpen(!isOpen)}
                    className="burger-image"
                    src={burger}
                    alt={"burger-image"}
                  />
                </div>
              )}
            </Row>
          </Container>
        </div>

        <div className="header-bot">
          <div className="container-header-bot">
            {!isSearchInputOpen && (
              <div className="box-element-header">
                <CatalogButton />
              </div>
            )}
            <div
              className={classNames("box-element-header", {
                "__header-input-search":
                  !ДоступенКаталогДоставкиИзМагазина &&
                  !ДоступенКаталогСамовывозаИзМагазина,
                "__width-box-input-search": isSearchInputOpen,
                // "__mobile-full-open-search":isSearchInputOpen && breakpoint === "mobileLarge"
              })}
            >
              <SearchInput />
            </div>
            {!isSearchInputOpen && (
              <>
                <div
                  className={classNames("box-element-header delivery-button", {
                    "delivery-button-off":
                      !ДоступенКаталогДоставкиИзМагазина &&
                      !ДоступенКаталогСамовывозаИзМагазина,
                  })}
                >
                  <DeliveryButton />
                </div>
                {/*TODO скрываем авторизацию и корзину*/}
                {/*<div className="box-element-header account-button">*/}
                {/*  <AccountButton />*/}
                {/*</div>*/}
                {/*<div className="box-element-header cart-button">*/}
                {/*  <CartButton />*/}
                {/*</div>*/}
              </>
            )}
          </div>
        </div>
        {breakpoint === "mobileLarge" && isLogin ? (
          <div className="box-header-delivery-button-mobile">
            <DeliveryButton className="header-delivery-button-mobile" />
          </div>
        ) : null}
      </header>
      <Offcanvas
        show={isOpen}
        onHide={() => setIsOpen(false)}
        placement="start"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Row className="justify-content-md-spacebetween __row-header-header">
              <Col xl={4} lg={4}>
                <SmartLogo />
              </Col>
            </Row>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Col xl={4} md={3}>
            <CurrentCity />
          </Col>
          {/* <DeliveryButton className="burger-delivery-button" /> */}
          <NavMenu
            containerClassName={"container-nav-burger"}
            arrayLists={[...headerLinks, ...headerLinksTop]}
            modifier={"burger-nav"}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HeaderBar;
