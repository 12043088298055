import { useAppDispatch, useAppSelector } from "store";

import { useEffect, useMemo } from "react";
import { Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import clock from "image/clock.svg";
import dish from "image/dish.svg";
import useBreakpoint from "use-breakpoint";

import { CarouselProducts, Cooking, InformationCard, Ingredients, VideoBlock } from "components";
import { BreadcrumbNav, CustomContainer } from "elements";
import { getRecipesBlock, recipesBlockResultSelector } from "features";
import { getWordForm } from "utils";

import "./Recipe.scss";

const BREAKPOINTS = {
  desktop: 1280,
  tablet: 768,
  mobile: 0,
};

const Recipe = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const { pathname } = useLocation();
  const { recipesId } = useParams<{ recipesId: string }>();
  const dataRecipes = useAppSelector(recipesBlockResultSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getRecipesBlock({}));
  }, []);

  const dataRecipe = useMemo(() => dataRecipes?.filter(elem => elem.Ссылка === recipesId)[0], [recipesId, dataRecipes]);

  const {
    ЗаголовокПревью,
    URLВидеоРецепта,
    Ингредиенты,
    ВремяПриготовления,
    КоличествоПорций,
    ИнформационныеБлоки,
    Артикулы,
  } = dataRecipe || {};

  const navLinks = [
    {
      label: "Главная",
      link: "/",
    },
    {
      label: "Рецепты",
      link: "/recipes",
    },
    {
      label: `${dataRecipe?.ЗаголовокПревью || "Loading..."}`,
      link: pathname,
    },
  ];

  return (
    <>
      {dataRecipes.length ? (
        <CustomContainer containerClassName="container-recipe">
          <>
            <Row className="row-breadcrumb-recipe">
              <BreadcrumbNav navLinks={navLinks} />
            </Row>
            <Row>
              <h1 className="title-recipe">{ЗаголовокПревью}</h1>
            </Row>
            <Row className="row-recipe-info">
              <VideoBlock url={URLВидеоРецепта} />
              {breakpoint === "mobile" ? (
                <Row className="row-information-card">
                  <InformationCard img={clock} title={"Время приготовления"} text={`${ВремяПриготовления} минут`} />
                  <InformationCard
                    img={dish}
                    title={"Количество порций"}
                    text={`${КоличествоПорций} ${getWordForm(КоличествоПорций, {
                      1: "порция",
                      2: "порции",
                      5: "порций",
                    })}`}
                  />
                </Row>
              ) : null}
              <Ingredients ingredients={Ингредиенты} articles={Артикулы} />
            </Row>
            {breakpoint === "mobile" ? null : (
              <Row className="row-information-card">
                <InformationCard img={clock} title={"Время приготовления"} text={`${ВремяПриготовления} минут`} />
                <InformationCard
                  img={dish}
                  title={"Количество порций"}
                  text={`${КоличествоПорций} ${getWordForm(КоличествоПорций, {
                    1: "порция",
                    2: "порции",
                    5: "порций",
                  })}`}
                />
              </Row>
            )}
            <Row className="row-cooking">
              <Cooking cookingSteps={ИнформационныеБлоки} />
            </Row>
            <Row className="row-slider">
              <p className="title-slider">Закажите ингредиенты в Smart</p>
              <CarouselProducts navLinks={navLinks} articles={Артикулы} />
            </Row>
          </>
        </CustomContainer>
      ) : null}
    </>
  );
};

export default Recipe;
